.entry-content>* {
    margin-bottom: 0 !important;
}

nav[class*=menu] li[class*=current-menu-]>a {
    font-weight: 700;
}

a {
    text-decoration: none !important;
}

.b-header {
    font-size: 16px !important;
    padding: 6px 50px !important;
    background: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
    &:hover {
        background: none;
    }
}

.ct-header {
    .ct-search-box {
        form {
            input[type="search"] {
                border: 2px solid #831C80;
                height: 40px;
                // background-image: linear-gradient(white, white), linear-gradient(to right, #831C80, #472482);
                // border: 2px solid;
                // border-image: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
                // background-origin: border-box;
                // background-clip: content-box, border-box;
            }
            button.search-submit {
                padding: 0 70px !important;
                background: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
                border-radius: 10px;
                min-height: 40px;
                height: 40px !important;
                &:after {
                    content: 'Zoek';
                }
                // &:hover {
                //     background: none;
                //     background-color: var(--paletteColor1);
                // }
            }
        }
    }
}

.h-style {
    mark {
        font-weight: 900;
        background: linear-gradient(93.36deg, #831C80 0%, #472482 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }
}

.h-svg {
    position: relative;
    .gb-icon {
        position: absolute;
        top: -50px;
        left: -55px;
    }
    .gb-headline-text {
        z-index: 999;
    }
}

.h-svg2 {
    position: relative;
    .gb-icon {
        position: absolute;
        top: -40px;
        left: -65px;
    }
    .gb-headline-text {
        z-index: 999;
    }
}

.h-svg3 {
    position: relative;
    .gb-icon {
        position: absolute;
        top: -28px;
        left: -48px;
    }
    .gb-headline-text {
        z-index: 999;
    }
}

.h-svg4 {
    position: relative;
    .gb-icon {
        position: absolute;
        top: -20px;
        left: -30px;
    }
    .gb-headline-text {
        z-index: 999;
    }
}

.b-svg {
    &:hover {
        .gb-icon {
            svg {
                fill: var(--paletteColor3, #14052D);
                path {
                    fill: var(--paletteColor3, #14052D);
                }
            }
        }
    }
}

.b-paragraph {
    margin-bottom: 10px;
}

.style-list {
    padding-left: 18px;
    margin-bottom: 10px;
}

.h-gradient-style {
    background: linear-gradient(93.36deg, #831C80 0%, #472482 100%), linear-gradient(0deg, #12022F, #12022F);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.wp-block-buttons {
    .wp-block-button {
        a {
            background: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
            &:hover {
                background: none;
            }
        }
    }
}

.custom-g-button {
    background: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
    &:hover {
        background: none;
    }
}

.is-style-outline {
    a {
        padding: 0 20px !important;
        color: #14052D !important;
        &:hover {
            color: #FFFFFF !important;
        }
    }
}

p {
    strong {
        mark {
            font-weight: 700;
        }
    }
}

.l-gradient {
    background: linear-gradient(90.66deg, #831C80 0%, #472482 100%);
}

.b-gradient {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(white, white), linear-gradient(to right, #831C80, #472482);
    border: 2px solid;
    border-image: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.c-shadow {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    h2,
    p {
        padding: 0 30px;
    }
}

.cont-grid-style {

}

.ct-pagination {
    justify-content: right !important;
    .ct-load-more-helper {
        .ct-button {
            background: linear-gradient(90.66deg, #831C80 0%, #472482 100%);
            // &:hover {
            //     background: #472482;
            // }
        }
    }
}

.nieuws-slider {
    display: block !important;
    .owl-item {
        padding: 0 37.5px;
    }
    .entries {
        display: block;
        margin: 0 -37.5px;
        article {
            position: relative;
            overflow: hidden;
            margin-top: 25px;
            margin-bottom: 25px;
            padding: 0;
            // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
            // border-radius: 25px;

            background-image: linear-gradient(white, white), linear-gradient(to right, #831C80, #472482);
            border: 4px solid;
            border-image: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            .ct-image-container {
                padding: 27px 27px 0 27px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            .entry-title {
                padding: 0 27px;
            }
            .entry-excerpt {
                padding: 0 27px;
            }
            .entry-button {
                border-radius: 10px;
                font-size: 16px !important;
                font-weight: 700 !important;
                margin-left: 27px;
                margin-right: 27px;
                // margin-bottom: 8px;
                margin-bottom: 35px;
                &:hover {
                    color: #FFFFFF;
                    // background: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
                    background-color: var(--paletteColor1);
                }
            }
        }
        .owl-nav {
            display: block !important;
            position: absolute;
            left: 0;
            right: 0;
            top: 244px;
            [class*=owl-] {
                font-size: 0;
            }
            .owl-prev.disabled {
                display: none;
            }
            // .owl-next.disabled {
            //     display: none;
            // }
            .owl-prev {
                float: left;
                // margin-left: -190px;
                position: absolute;
                left: -190px;
                // &:before {
                //     content: '';
                //     display: block;
                //     width: 48px;
                //     height: 48px;
                //     background: url(/wp-content/uploads/2023/05/icon-right.png);
                //     background-position: center center;
                //     background-size: contain;
                //     background-repeat: no-repeat;
                // }
            }
            .owl-next {
                float: right;
                // margin-right: -190px;
                position: absolute;
                right: -190px;
                &:before {
                    content: '';
                    display: block;
                    width: 72px;
                    height: 62px;
                    background: url(/wp-content/uploads/2023/05/icon-right.png);
                    background-position: center center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}

.post-type-archive-vacatures {
    .entries {
        article {
            position: relative;
            overflow: hidden;
            padding: 0 !important;
            // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
            // border-radius: 25px;
    
            background-image: linear-gradient(white, white), linear-gradient(to right, #831C80, #472482);
            border: 4px solid !important;
            border-image: linear-gradient(93.36deg, #831C80 0%, #472482 100%) !important;
            background-origin: border-box;
            background-clip: content-box, border-box;
            .entry-title {
                padding: 45px 25px 0 25px;
            }
            ul.entry-meta {
                padding: 0 25px;
                margin-bottom: 10px !important;
                li.meta-categories {
                    position: relative;
                    &:before {
                        content: 'Locatie:';
                        margin-right: 4px;
                    }
                }
            }
            .entry-excerpt {
                padding: 0 25px;
            }
            .entry-button {
                border-radius: 10px;
                font-size: 16px !important;
                font-weight: 700 !important;
                margin-left: 25px;
                margin-right: 25px;
                // margin-bottom: 8px;
                margin-bottom: 45px;
                &:hover {
                    color: #FFFFFF;
                    // background: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
                    background-color: var(--paletteColor1);
                }
            }
        }
    }
}

.category {
    .entries {
        grid-row-gap: 54px;
        article {
            position: relative;
            overflow: hidden;
            padding: 0 !important;
            // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
            // border-radius: 25px;
    
            background-image: linear-gradient(white, white), linear-gradient(to right, #831C80, #472482);
            border: 4px solid !important;
            border-image: linear-gradient(93.36deg, #831C80 0%, #472482 100%) !important;
            background-origin: border-box;
            background-clip: content-box, border-box;
            .ct-image-container {
                padding: 25px 25px 0 25px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            .entry-title {
                padding: 0 27px;
            }
            .entry-excerpt {
                padding: 0 27px;
            }
            .entry-button {
                border-radius: 10px;
                font-size: 16px !important;
                font-weight: 700 !important;
                margin-left: 27px;
                margin-right: 27px;
                // margin-bottom: 8px;
                margin-bottom: 35px;
                &:hover {
                    color: #FFFFFF;
                    // background: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
                    background-color: var(--paletteColor1);
                }
            }
        }
    }
}

.filter-tax-custom-field {
    .gb-inside-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 10px;
        .gb-style {
            margin-bottom: 10px !important;
            padding: 6px 30px;
            background: linear-gradient(93.36deg, #831C80 0%, #472482 100%);
            border-radius: 25px;
        }
    }
}

.img-radius {
    img {
        border-radius: 20px;
    }
}

.container-gradient {
    background-color: transparent;
    background-image: linear-gradient(100deg, #462482 0%, #821d80 80%);
    box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.18);
}

.ct-footer {
    [data-row="middle"] {
        .ct-container {
            .ct-widget {
                padding-left: 16px;
                .widget-title {
                    position: relative;
                    display: inline-block;
                    margin-bottom: 10px !important;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 8px;
                        left: -16px;
                        width: 10px;
                        height: 10px;
                        background-color: var(--paletteColor2);
                        border-radius: 100%;
                    }
                }
            }
            #block-6 {
                margin-top: 12px;
            }
            #block-8 {
                margin-top: 0;
            }
        }
    }
    [data-row="bottom"] {
        background: linear-gradient(90.12deg, #831C80 0%, #482583 100%);
    }
}

article>.entry-content>*.alignfull {
    .gb-inside-container {
        width: var(--default-editor, var(--block-width));
        width: var(--container-width);
        margin-left: var(--default-editor, auto);
        margin-right: var(--default-editor, auto);
    }
}

// article>.entry-content>*.gb-container-279ed1ce.alignfull {
//     .gb-inside-container {
//         width: var(--container-width);
//     }
// }

@include break-below(lgm) {
    .img-left {
        text-align: left;
    }
}

@include break-below(lgt) {
    .nieuws-slider {
        .entries {
            .owl-nav {
                .owl-prev {
                    left: -80px;
                }
                .owl-next {
                    right: -80px;
                }
            }
        }
    }
}

@include break-below(lg) {
    .nieuws-slider {
        .entries {
            .owl-item {
                padding: 0 25px;
            }
            .owl-nav {
                .owl-prev {
                    left: -55px;
                    &:before {
                        width: 39px;
                        height: 36px;
                    }
                }
                .owl-next {
                    right: -55px;
                    &:before {
                        width: 39px;
                        height: 36px;
                    }
                }
            }
        }
    }
    .category {
        .entries {
            --grid-template-columns: repeat(2, minmax(0, 1fr));
            --grid-columns-gap: 20px;
            grid-row-gap: 30px;
        }
    }
}

@include break-below(mdm) {
    .post-type-archive-vacatures {
        .entries {
            article {
                .entry-title {
                    padding: 25px 25px 0 25px;
                    margin-bottom: 10px !important;
                }
                .entry-excerpt {
                    font-size: 14px;
                    line-height: 25px;
                }
            }
        }
    }
    .category {
        .entries {
            article {
                .entry-title {
                    margin-top: 0 !important;
                    margin-bottom: 10px !important;
                    line-height: 35px !important;
                }
                .entry-excerpt {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
}

@include break-below(md) {
    .nieuws-slider {
        .entries {
            article {
                .ct-image-container {
                    padding: initial;
                    margin-bottom: 7px !important;
                }
                .entry-title {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                    padding: 0 15px;
                }
                .entry-excerpt {
                    padding: 0 15px;
                    font-size: 14px;
                    line-height: 25px;
                }
                .entry-button {
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}

@include break-below(sm) {
    .c-center {
        figure {
            text-align: center;
        }
    }
    .category {
        .entries {
            --grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}

@include break-below(xs) {
    .nieuws-slider {
        .entries {
            .owl-nav {
                .owl-prev {
                    left: -45px;
                    &:before {
                        width: 30px;
                        height: 30px;
                    }
                }
                .owl-next {
                    right: -45px;
                    &:before {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}